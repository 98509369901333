import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('photos_uploader')

export default {
  computed: mapState({
    images: (state) => state.images,
    editImageUrl: (state) => state.editImageUrl,
    idImageToEdit: (state) => state.idImageToEdit,
    imageDescription: (state) => state.imageDescription,
    isFromEditPhoto: (state) => state.isFromEditPhoto,
    photoTags: (state) => state.photoTags,
    tagsValue: (state) => state.tagsValue,
    loading: (state) => state.loading,
  }),
  methods: mapActions([
    'loadImages',
    'setImages',
    'setEditImageUrl',
    'setIdImageToEdit',
    'setImageDescription',
    'setIsFromEditPhoto',
    'setPhotoTags',
    'setTagsValue',
    'setLoading',
    'cleanState',
  ]),
}
