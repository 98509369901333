<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 justify-between overflow-auto">
      <ul
        class="w-5/12 overflow-auto p-8 bg-gray-100 text-black"
        aria-label="notes"
      >
        <li>
          Because they carousel through at a decent pace, more pictures is
          better. We recommend you add photos for every conversation topic and
          level of detail that you can.
        </li>
        <li>
          You don’t need a professional photographer. If you have somebody with
          a decent eye for photos (i.e they know the rule of thirds), even
          mobile phone photos can look great on the web.
        </li>
        <li>
          Keep your photo captions short. No more than two lines long.
          Otherwise, it will not be as aesthetically pleasing on the screen.
        </li>
      </ul>
      <div class="w-full ml-3 overflow-auto">
        <div class="form-row">
          <div class="form-col">
            <div>
              <label for="categories">main topic</label>
              <text-input
                v-if="this.optionalData.category"
                :value="this.optionalData.category.intent_category_name"
                disabled
              />
              <Dropdown
                v-if="!this.optionalData.category"
                trackBy="intent_category_name"
                label="intent_category_name"
                :options="this.optionalData.categories"
                :value="this.optionalData.category"
                @input="setCategoryValue"
                objectMode
              />
              <span v-if="hasError('optionalData.category')" class="form-error">
                {{ showError('optionalData.category') }}
              </span>
            </div>
            <div class="my-5">
              <label for="categories">conversation detail</label>
              <text-input
                v-if="this.optionalData.intent"
                :value="this.optionalData.intent.suggestion_text"
                disabled
              />
              <Dropdown
                v-if="!this.optionalData.intent"
                trackBy="intent_name"
                label="suggestion_text"
                :options="this.optionalData.intents"
                :value="this.optionalData.intent"
                @input="setIntentValue"
                objectMode
              />
              <span v-if="hasError('optionalData.intent')" class="form-error">
                {{ showError('optionalData.intent') }}
              </span>
            </div>
            <div>
              <label for="categories">next level detail</label>
              <text-input
                v-if="this.optionalData.subIntent"
                :value="this.optionalData.subIntent.suggestion_text"
                disabled
              />
              <Dropdown
                v-if="!this.optionalData.subIntent"
                trackBy="intent_name"
                label="suggestion_text"
                :options="this.optionalData.subIntents"
                :value="this.optionalData.subIntent"
                @input="setSubIntentValue"
                objectMode
              />
              <span v-if="hasError('optionalData.subIntent')" class="form-error">
                {{ showError('optionalData.subIntent') }}
              </span>
            </div>
          </div>
          <div class="form-col">
            <p class="font-bold text-sm mb-1" for="contentText">
              photo description (required)
            </p>
            <text-input
              inputClass="h-40"
              id="contentText"
              @input="contentText !== '' ? (validator.errorsBag = []) : ''"
              v-model="contentText"
              multiline
            />
            <span v-if="hasError('contentText')" class="form-error">
              {{ showError('contentText') }}
            </span>
          </div>
        </div>
        <div class="w-full relative">
          <div>
            <file-manager
              :library="getLibraryImageFilesByBaseDirectory(['marketing', 'dh'])"
              :libraryFilters="['marketing', 'dh']"
              :main-directory="'dh'"
              :max-upload-size="20"
              :max-nb-of-files="10"
              :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
              v-model="imagestoUpload"
            >
            </file-manager>
          </div>
        </div>
      </div>
      <modal-footer
        :footer="footer"
        :primary="onSave"
      ></modal-footer>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'
import Loader from '@/components/ui/Loader'
import PhotosMixin from '@/components/digital_human/PhotosMixin'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import FileManager from "@/components/ui/filemanager/FileManager";
import TextInput from '@/components/ui/TextInput';
import Dropdown from '@/components/ui/Dropdown';

export default {
  name: 'CreatePhoto',
  components: { Loader, ModalFooter, FileManager, TextInput, Dropdown },
  mixins: [
    AuthMixin,
    ModalNavigation,
    NotifyMixin,
    ConversationMixin,
    PhotosMixin,
  ],
  data() {
    return {
      optionalData: {
        category: null,
        intent: null,
        subIntent: null,
        categories: [],
        intents: [],
        subIntents: [],
      },
      hasSelectedImgs: false,
      selectedImages: [],
      imagestoUpload: [],
      fileImage: null,
      contentText: '',
      showLabel: false,
      imageFocus: false,
      footer: {
        primaryButton: 'add photo(s)',
      },
    }
  },
  computed: {
    ...mapGetters({
        getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
        getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
    }),
  },
  methods: {
    fetchCategories() {
      this.setLoading(true)

      this.$dhDataProvider
        .getList('categoriesCustom')
        .then((res) => (this.optionalData.categories = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)

      if (this.community && this.community?.id.trim() !== '')
        this.$dhDataProvider
          .getList('customIntents', {
            communityId: this.community?.id,
            category: categoryName,
          })
          .then((res) => (this.optionalData.intents = res))
          .catch((error) => this.notifyError(error.message))
          .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('subIntentsLevel', {
          communityId: this.community?.id,
          intent: intentName,
        })
        .then((res) => (this.optionalData.subIntents = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchPhotos(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('cards', {
          community_id: this.community.id,
          intent: intentName,
        })
        .then((res) => this.loadPhotos(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    setCategoryValue(value) {
      this.optionalData.intents = []
      this.optionalData.subIntents = []
      this.optionalData.category = null
      this.optionalData.intent = null
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.category = value
      this.fetchIntents(value?.description)
    },
    setIntentValue(value) {
      this.optionalData.subIntents = []
      this.optionalData.intent = null
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.intent = value
      this.fetchSubIntents(value?.intent_name)
    },
    setSubIntentValue(value) {
      if (!value) return
      this.optionalData.subIntent = value
    },
    onSave() {
      if (this.category && this.intent && this.subIntent) {
        this.optionalData.category = this.category
        this.optionalData.intent = this.intent
        this.optionalData.subIntent = this.subIntent
      }
      if (!this.validator.passes() || !this.hasSelectedImgs) {
        return;
      }

      if (this.contentText !== '') this.validator.errorsBag = []

      this.setLoading(true)
      this.$dhDataProvider
        .create('card', {
          data: {
            id_community: this.community?.id,
            intent_name:
              this.optionalData.subIntent?.intent_name === 'general'
                ? this.optionalData.intent.intent_name
                : this.optionalData.subIntent?.intent_name,
            content_text: this.contentText,
            image_url: this.selectedImages,
          },
        })
        .then(() => {
          if (!this.category || !this.intent || !this.subIntent) {
            this.setCategory(this.optionalData.category)
            this.setIntent(this.optionalData.intent)
            this.setSubIntent(this.optionalData.subIntent)
          }
          this.contentText = ''
          this.notifySuccess('The image(s) has been added successfully')
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          this.setLoading(false)
          this.fetchPhotos(
            this.subIntent?.intent_name !== 'general'
              ? this.subIntent?.intent_name
              : this.intent?.intent_name
          )
          this.$router.push({name: 'conversations_responses.index'});
        })
    },
    loadFields() {
      this.optionalData.category = this.category
      this.optionalData.intent = this.intent
      this.optionalData.subIntent = this.subIntent
    },
  },
  watch: {
    imagestoUpload: {
      handler: function() {
        if (this.imagestoUpload.length) {
          this.selectedImages = this.imagestoUpload.map((uuid) => this.getThumbnailUrlByFileId(uuid));
          this.hasSelectedImgs = this.selectedImages && this.selectedImages.length > 0;
        } else this.hasSelectedImgs = false;
      },
      deep: true,
      immediate: true
    }
  },
  validations: {
    contentText: 'required',
    'optionalData.category': 'required',
    'optionalData.intent': 'required',
    'optionalData.subIntent': 'required',
  },
  created() {
    this.initValidator();
    this.fetchCategories();
    this.loadFields();
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      );
    }
    if (!this.validator.passes() || !this.hasSelectedImgs) this.isInvalid = true
    this.validator.errorsBag = []
  },

}
</script>

<style scoped>
.imgText {
  text-decoration: underline;
  cursor: pointer;
  @apply text-blue-500;
}
.media-preview {
  position: relative;
}
.button-disabled {
  opacity: 0.5;
}
.boxSize {
  width: 73%;
}
.isDisabled {
  @apply text-gray-400 bg-gray-600;
}
.media-preview {
  position: relative;
}

.media-preview:hover .media-preview-image {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.media-preview-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
</style>
